<template>
  <v-dialog width="550" persistent v-model="isOpen">
    <v-card>
      <v-card-title>
        <span>Request Reset Password Link</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="isOpenLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>Lorem ipsum dolor sit amet.</v-card-subtitle>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              filled
              placeholder="E-mail"
              rounded
              v-model="email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              depressed
              class="text-capitalize rounded-lg"
              rounded
              color="primary"
              :disabled="!email"
              :loading="isLoading"
              @click="request"
              >Request</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    isOpen: Boolean
  },

  data() {
    return {
      isOpenLocal: false,
      isLoading: false,
      email: null
    };
  },

  watch: {
    isOpen(value) {
      this.isOpenLocal = value;
    },
    isOpenLocal(value) {
      this.$emit("update:isOpen", value);
    }
  },

  methods: {
    request() {
      const payload = {
        email: this.email || null
      };
      this.isLoading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/password/email`, payload, {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(() => {
          this.MIXINS_SHOW_SNACKBAR(
            "success",
            "Password reset form link sent at your E-mail."
          );
          this.isOpenLocal = false;
          this.email = null;
        })
        .catch(error => {
          console.log({ error });
          let objectError = error.response.data.errors;
          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];
            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);
            break;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
