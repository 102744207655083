<template>
  <div class="fill-height d-flex align-center pa-4">
    <v-container>
      <v-row>
        <v-col>
          <v-img :src="logo" alt="Logo" height="60" position="start" contain />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-gray headline font-weight-bold">
            Welcome
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="form.email"
              :rules="[...MIXINS_REQUIRED_RULES, ...MIXINS_EMAIL_RULES]"
              label="Email"
              dense
            />
            <v-text-field
              v-model="form.password"
              :rules="MIXINS_REQUIRED_RULES"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              @click:append="showPassword = !showPassword"
              dense
            />
            <v-btn
              class="bg-secondary text-color-white mb-4 rounded-lg"
              small
              depressed
              :loading="loading"
              @click="login"
            >
              Log in
            </v-btn>
            <div class="text-color-title">
              <small
                class="forgot-password-link cursor-pointer text-decoration-underline"
                @click="isDialogOpen = true"
              >
                Forgot your password?
              </small>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <request-reset-password-dialog
      :is-open.sync="isDialogOpen"
    ></request-reset-password-dialog>
    <global-snackbar />
  </div>
</template>

<script>
import Storage from "@/services/storage.js";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import RequestResetPasswordDialog from "@/views/login/dialogs/RequestResetPasswordDialog";

const signUpRepository = RepositoryFactory.get("sign-up");
let storage = new Storage();

export default {
  components: { RequestResetPasswordDialog },

  data: () => ({
    form: {},
    valid: false,
    logo: require("../../../assets/logo/inspire_church_logo_bw.png"),
    showPassword: false,
    loading: false,
    isDialogOpen: false
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    login() {
      if (this.validate()) {
        this.loading = true;

        signUpRepository
          .login(this.form)
          .then(response => {
            let {
              data: { responseData: data }
            } = response;

            storage.set("user", data.user, true);
            storage.set("token", data.token, true);
            storage.set("authenticated", true);

            this.MIXINS_SHOW_SNACKBAR("success", "Login Successful.");

            setTimeout(() => {
              this.MIXINS_GO_TO_PAGE("/");
            }, 1000);
          })
          .catch(error => {
            let message = error.response.data.errors[0];

            this.MIXINS_SHOW_SNACKBAR("error", message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  // mounted() {
  //   this.form = {
  //     email: "fourello@dev.com",
  //     password: "fourello123!"
  //   };
  // }
};
</script>
